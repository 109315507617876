/* Fonts BPC */
@font-face{
    font-family: Radomir;
    font-style: normal;
    font-weight: normal;                  
    src: local('RadomirTinkov'),url('../assets/fonts/bpc-fonts/bpc-Radomir.woff');
  }
  .swal2-title {
    font-family: Radomir, sans-serif;
  }
  
  body, html {
    overflow-x: hidden;
  }
   
  /* Home */
  
  .container-grid {
    margin-top: 7rem;
  }
  
  .grid-banner {
    grid-gap: 40px;
    direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .welcome-card-tittle {
    color: #002850;
    font-family: Radomir, sans-serif;
    line-height: 60px;
    font-size: 30px;
  }
  
  .logo-paid-account {
    content: url("https://www.pibank.es/wp-content/uploads/2019/09/Cuenta_remunerada.svg");
    width: 50%;
  }
  
  .info-card {
    display: inline-block;
    padding: 30px 75px;
    background-color: #ffdc00;
    border-radius: 100px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: left;
    max-width: 580px;
    margin-bottom: 10px;
  }
  
  .info-card-tittle {
    color: #002850;
    font-family: Radomir, sans-serif;
    line-height: 60px;
    font-size: 20px;
  }
  
  /* Navbar */
  .appbar-navbar {
    background: #ffffff;
  }
  
  .menu-icon {
    color: #002850;
  }
  
  /* Meeting */
  
  .control-bar {
    margin-top: 4.5rem;
    height: 35rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .color-bar {
    background-color: #ffdc00;
    max-width: 580px;
    margin-bottom: 10px;
  }
  
  /* Meeting Form*/
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
  
  .position-card {
    margin-top: 4.5rem;
  }
  
  .welcome-text {
    color: #002850;
    font-family: Radomir, sans-serif;
    text-align: center;
  }
  
  .flex {
    color: #0f265c;
    font-family: Radomir, sans-serif;
  }
  
  .flex-button {
    margin-top: 2rem;
  }
  
  .decoration-link {
    text-decoration: none;
  }
  
  .redirect-button {
    font-family: Radomir, sans-serif;
    color: #0f265c;
  }
  